<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- onboarding fee -->
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-2
                  mt-3
                "
              >
                Onboarding fees
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mt-3
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="onboarding-group"
                    id="onboarding-group-input"
                    v-model="onboardingFee"
                    isRequired
                    :state="onboardingFeeError.status"
                    :invalidFeedback="`${onboardingFeeError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="
                          px-3 px-md-4
                          icon-btn
                          border border-gray
                          overflow-hidden
                          shadow-sm
                        "
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="dollar-sign"
                          aria-label="dollar-sign"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <!-- onboarding fee -->
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-2
                  mt-3
                "
              >
                Minimum contribution amount
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mt-3
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="onboarding-group"
                    id="onboarding-group-input"
                    v-model="minimumAmount"
                    isRequired
                    :state="minimumAmountError.status"
                    :invalidFeedback="`${minimumAmountError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="
                          px-3 px-md-4
                          icon-btn
                          border border-gray
                          overflow-hidden
                          shadow-sm
                        "
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="dollar-sign"
                          aria-label="dollar-sign"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <hr class="border-bottom-1 w-100 bg-light my-4" />
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-2
                  mt-3
                "
              >
                PAC commission rate
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mt-3
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="commission-rate-group"
                    id="commission-rate-group-input"
                    v-model="commissionRate"
                    isRequired
                    type="number"
                    :state="commissionRateError.status"
                    :invalidFeedback="`${commissionRateError.message}`"
                    form="reset-earnings-form"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="
                          px-3 px-md-4
                          icon-btn
                          border border-gray
                          overflow-hidden
                          shadow-sm
                        "
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="percent"
                          aria-label="percent"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-2
                  mt-3
                "
              >
                PayPal fees
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mt-3
                "
                no-gutters
              >
                <b-col cols="12" lg="5">
                  <FormInputGroup
                    groupId="stripe-rate-group"
                    id="stripe-rate-group-input"
                    v-model="stripeRate"
                    isRequired
                    :state="stripeRateError.status"
                    :invalidFeedback="`${stripeRateError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="
                          px-3 px-md-4
                          icon-btn
                          border border-gray
                          overflow-hidden
                          shadow-sm
                        "
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="percent"
                          aria-label="percent"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
                <b-col
                  class="
                    d-flex
                    flex-column
                    text-center
                    px-4
                    ml-2
                    py-3
                    left-column
                  "
                >
                  <font-awesome-icon
                    class="text-dark text-center"
                    icon="plus"
                    aria-label="Help"
                    size="lg"
                  ></font-awesome-icon>
                </b-col>
                <b-col cols="12" lg="5">
                  <FormInputGroup
                    groupId="stripe-fee-group"
                    id="stripe-fee-group-input"
                    class="
                      text-muted-gray
                      font-primary
                      contact-number-input
                      bg-white
                    "
                    v-model="stripeFee"
                    :state="stripeFeeError.status"
                    :invalidFeedback="stripeFeeError.message"
                    isRequired
                    trim
                    form="reset-earnings-form"
                    type="number"
                    isPrepend
                  >
                    <template v-slot:prepend>
                      <font-awesome-icon
                        class="text-prime-gray text-center"
                        icon="dollar-sign"
                        aria-label="dollar-sign"
                        size="lg"
                      ></font-awesome-icon>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <!-- form action -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  w-100
                  mt-3
                "
                no-gutters
              >
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    px-0
                    py-2
                  "
                  cols="9"
                  sm="6"
                  v-if="captchakey"
                >
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    sitekey="6Lek-WAhAAAAADAfw-prVYUzhPNrEi4Sz28hdzxI"
                  >
                  </vue-recaptcha>
                </b-col>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  w-100
                "
                no-gutters
              >
                <!-- reset password button -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    px-0
                    py-2
                  "
                  cols="9"
                  sm="6"
                >
                  <FormButton
                    variant="primary"
                    isBlock
                    class="font-secondary font-weight-normal text-white mt-2"
                    type="submit"
                    :isLoading="isAPILoading"
                    :isDisabled="!this.grecaptchaToken"
                    @click.native="submitBtn"
                    >Save Changes</FormButton
                  >
                </b-col>
              </b-row>
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <b-col class="px-0 px-md-2 py-3 left-column">
              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="
                    text-left text-dark
                    font-weight-semi-bold font-primary
                    mb-2
                    mt-3
                  "
                >
                  Manual Campaign Approval
                </h6>
                <div
                  class="d-flex flex-row align-items-center justify-content-end"
                >
                  <b-form-checkbox
                    class="checkbox-button"
                    v-model="manualApproval"
                    @change="changeManulApproval"
                    name="check-button "
                    switch
                    size="lg"
                    form="create-supplier-form"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="
                    text-left text-dark
                    font-weight-semi-bold font-primary
                    mb-2
                    mt-3
                  "
                >
                  Use Paypal ACDC
                </h6>
                <div
                  class="d-flex flex-row align-items-center justify-content-end"
                >
                  <b-form-checkbox
                    class="checkbox-button"
                    v-model="switchCardPayment"
                    @change="changeSwitchCardPayment"
                    name="check-button"
                    switch
                    size="lg"
                    form="create-supplier-form"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-col>
            <b-overlay :show="isLoading" no-wrap></b-overlay>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";

// services
// services
import {
  CreateEarnings,
  GetEarnings,
  ManualApproval,
  SwitchPaymentGateway,
} from "../services/earnings.service";
export default {
  name: "Earnings",
  components: {
    HomeLayout,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      adminId: null,
      isReset: false,
      isLoading: false,
      isAPILoading: false,
      commissionRate: null,
      commissionRateError: {
        status: null,
        message: "",
      },
      stripeRate: null,
      stripeRateError: {
        status: null,
        message: "",
      },
      stripeFee: null,
      stripeFeeError: {
        status: null,
        message: "",
      },
      onboardingFee: null,
      onboardingFeeError: {
        status: null,
        message: "",
      },
      renewalustomText: null,
      renewalCustomTextError: {
        status: null,
        message: "",
      },
      renewalCustomTextCount: 80,
      grecaptchaToken: null,
      manualApproval: undefined,
      minimumAmount: null,
      minimumAmountError: {
        status: null,
        message: "",
      },
      captchakey: null,
    };
  },

  mounted() {
    this.initFn();
    this.captchakey = process.env.VUE_APP_CAPTCHA_KEY;
  },
  watch: {
    manualApproval(val) {
      console.log("Approval Changed", val);
    },
    commissionRate(val) {
      if (val) {
        this.commissionRateError.status = true;
      } else {
        this.commissionRateError.status = false;
        this.commissionRateError.message = "Please fill in the field";
      }
    },
    stripeRate(val) {
      if (val) {
        this.stripeRateError.status = true;
      } else {
        this.stripeRateError.status = false;
        this.stripeRateError.message = "Please fill in the field";
      }
    },
    stripeFee(val) {
      if (val) {
        this.stripeFeeError.status = true;
      } else {
        this.stripeFeeError.status = false;
        this.stripeFeeError.message = "Please fill in the field";
      }
    },
    onboardingFee(val) {
      if (val) {
        this.onboardingFeeError.status = true;
      } else {
        this.onboardingFeeError.status = false;
        this.onboardingFeeError.message = "Please fill in the field";
      }
    },
    minimumAmount(val) {
      if (val) {
        this.minimumAmountError.status = true;
      } else {
        this.minimumAmountError.status = false;
        this.minimumAmountError.message = "Please fill in the field";
      }
    },
    renewalustomText(val) {
      if (val) {
        this.renewalCustomTextError.status = true;
        if (val.length <= this.renewalCustomTextCount) {
          this.renewalCustomTextError.status = true;
        } else {
          this.renewalCustomTextError.status = false;
          this.renewalCustomTextError.message = `Renewal custom text should have less than ${this.renewalCustomTextCount} characters`;
        }
      } else {
        this.renewalCustomTextError.status = false;
        this.renewalCustomTextError.message =
          "Please fill in the renewal custom Text";
      }
    },
  },
  methods: {
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetEarnings();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.manualApproval = details.manual_approval;
          this.switchCard = details.switch_card;
          this.commissionRate = details.platform_percent;
          this.stripeRate = details.stripe_percent;
          this.stripeFee = details.stripe_fixed;
          this.onboardingFee = details.platform_onboarding_fee;
          this.minimumAmount = details.minimum_contribution;
          this.switchCardPayment = details.gateway == "PAYPAL_ADV";
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Earnings details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    changeManulApproval() {
      ManualApproval(this.manualApproval).then((res) => {
        let variant = "success";
        let msg;
        if (this.manualApproval) {
          msg = "Manual approval enabled";
        } else {
          msg = "Manual approval disabled";
        }

        if (res.status != 200) {
          variant = "danger";
          msg = "Something went wrong. Please try again later.";
        }

        let payloadNotify = {
          isToast: true,
          title: "Manual Approval",
          content: msg,
          variant,
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      });
    },
    changeSwitchCardPayment() {
      const gatewayType = this.switchCardPayment ? "PAYPAL_ADV" : "PAYPAL_STD";
      SwitchPaymentGateway(gatewayType).then((res) => {
        let variant = "success";
        let msg;
        if (gatewayType == "PAYPAL_ADV") {
          msg = "Advance Credit & Debit Card checkout enabled";
        } else {
          msg = "PayPal standard checkout enabled";
        }

        if (res.status != 200) {
          variant = "danger";
          msg = "Something went wrong. Please try again later.";
        }

        let payloadNotify = {
          isToast: true,
          title: "Advance Credit & Debit Card Payments Approval",
          content: msg,
          variant,
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      });
    },
    async submitBtn() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          stripe_percent: this.stripeRate,
          stripe_fixed: this.stripeFee,
          paypal_percent: this.stripeRate,
          paypal_fixed: this.stripeFee,
          platform_percent: this.commissionRate,
          platform_onboarding_fee: this.onboardingFee,
          minimum_contribution: this.minimumAmount,
          captcha_token: this.grecaptchaToken,
        };
        this.isAPILoading = true;
        try {
          let res = await CreateEarnings(payload);

          if (res.response && res.response.status != 200) {
            let message = res.response?.data?.others;

            if (!message) {
              message = "Something went wrong";
            }

            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: "Details cannot be saved",
              list: [message],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            return;
          }

          const data = res.data;

          if (data.code == 200 && data.message == "success") {
            console.log(data, "testing");
            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: `Details has been successfully created`,
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.isInfoSubmitted = true;
            this.initFn();
            try {
              this.onCaptchaExpired();
            } catch (error) {
              console.warn("rc initializing failed");
            }
            setTimeout(() => this.$emit("reloadFn"), 600);
          } else {
            // display email error message in the form field
            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: "Details cannot be saved",
              list: [data.details],
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log("Request failed ", error);

          let payloadNotify = {
            isToast: true,
            title: "Create Earnings",
            content: "Details cannot be saved",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isAPILoading = false;
          this.isInfoSubmitted = true;
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Create Earnings",
          content: "Details cannot be saved ",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    customTxtBtn() {},
    errorFn() {
      var errorList = [];

      if (!this.commissionRate) {
        this.commissionRateError.status = false;
        this.commissionRateError.message = "Please fill in commission rate";
        errorList.push(this.commissionRateError.message);
      }
      if (!this.onboardingFee) {
        this.onboardingFeeError.status = false;
        this.onboardingFeeError.message = "Please fill in onboarding fee";
        errorList.push(this.onboardingFeeError.message);
      }
      if (!this.stripeRate) {
        this.stripeRateError.status = false;
        this.stripeRateError.message = "Please fill in stripe rate";
        errorList.push(this.stripeRateError.message);
      }
      if (!this.stripeFee) {
        this.stripeFeeError.status = false;
        this.stripeFeeError.message = "Please fill in stripe fixed";
        errorList.push(this.stripeFeeError.message);
      }
      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.commissionRate = null;
      this.stripeRate = null;
      this.stripeFee = null;
    },
    onCaptchaVerified(recaptchaToken) {
      this.grecaptchaToken = recaptchaToken;
      console.log("token", recaptchaToken);
    },
    onCaptchaExpired() {
      this.grecaptchaToken = null;
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style scoped>
/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}
.inner-row-grid-switch {
  display: grid;
  grid-template-columns: 60% 30%;
  grid-gap: 10%;
}
.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
}
.contact-number-input.form-group >>> input.form-control {
  border-left: 0px solid transparent;
  border-radius: 0px 4px 4px 0px;
}
.contact-number-input >>> .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  min-width: 60px;
  margin-left: 0;
}
.checkbox-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}
.checkbox-button >>> .custom-control-label:before {
  border-color: var(--primary);
  color: var(--primary);
}
.column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
}
</style>
